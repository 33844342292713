import React from 'react';
import { useRouter } from 'next/router';

import { ButtonType } from 'legacy/common/components/button/Button';
import { VerificationSessionDto } from 'legacy/auth/types';
import { useResendLoginVerificationCodeCommand } from 'legacy/auth/hooks';
import AlertModal from 'legacy/common/components/modal/AlertModal';
import * as toast from 'legacy/common/utils/toast';
import { logEvent } from 'legacy/common/utils/amplitude';
import { AnalyticsEvent } from 'legacy/common/constants/events';

interface Props {
  verificationSession: VerificationSessionDto;
  onSendConfirmationCodeSuccess: () => void;
  onClose: () => void;
}

const SendConfirmationCodeToEmailModal: React.FC<Props> = ({
  verificationSession,
  onSendConfirmationCodeSuccess,
  onClose
}) => {
  const router = useRouter();
  const resendLoginVerificationCode = useResendLoginVerificationCodeCommand();

  const handleOnClickSendCode = () => {
    logEvent(AnalyticsEvent.CLICK_SEND_CONFIRMATION_CODE_TO_EMAIL);

    resendLoginVerificationCode.mutate(
      {
        verificationSessionId: verificationSession.verificationSessionId,
        deliveryMethod: 'email'
      },
      {
        onSuccess: () => {
          toast.success(
            `Confirmation code sent to ${verificationSession.obfuscatedEmail}`
          );
          onSendConfirmationCodeSuccess();
          onClose();
        }
      }
    );
  };

  const handleOnClickContactSupport = () => {
    router.push('/contact');
  };

  return (
    <AlertModal
      title="Send Code To Email"
      message={
        verificationSession.obfuscatedEmail
          ? `Your confirmation code will be sent to your email address ${verificationSession.obfuscatedEmail}.`
          : 'You do not have an email address linked to your account.'
      }
      submitButtonType={ButtonType.Primary}
      error={resendLoginVerificationCode.error?.message}
      isSubmitting={resendLoginVerificationCode.isLoading}
      onSubmit={
        verificationSession.obfuscatedEmail
          ? handleOnClickSendCode
          : handleOnClickContactSupport
      }
      submitLabel={
        verificationSession.obfuscatedEmail ? 'Send Code' : 'Contact Support'
      }
      onCancel={onClose}
    />
  );
};

export default SendConfirmationCodeToEmailModal;
