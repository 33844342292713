import styles from 'legacy/common/components/form/Form.module.scss';

import React from 'react';
import * as Yup from 'yup';

import classnames from 'classnames';
import { Form, Formik } from 'formik';

import { isEmailAddress } from 'legacy/auth/helpers';

import Button, { ButtonType } from 'legacy/common/components/button/Button';
import PhoneEmailInput from '../phone-email-input/PhoneEmailInput';
import { getCallingCode, getCountryCode } from 'legacy/common/utils/country';

const LoginSchema = Yup.object().shape({
  phoneOrEmail: Yup.string()
    .required('Required')
    // https://github.com/jquense/yup/issues/743#issuecomment-884851222
    .test('is-valid-phone-or-email', '', (value, context) => {
      if (!value) {
        return true;
      }

      if (value.includes('@')) {
        return (
          isEmailAddress(value) ||
          context.createError({
            message: 'Invalid email address'
          })
        );
      }

      // Assume the user is attempting to enter a phone number
      return (
        value.length >= 5 ||
        context.createError({
          message: 'Must be at least 5 digits'
        })
      );
    })
});

type LoginFormProps = {
  isLoading: boolean;
  errorMessage: string;
  onSubmit: (values: {
    phoneOrEmail: string;
    countryCode: string;
    callingCode: string;
  }) => void;
};

const LoginForm: React.FC<LoginFormProps> = ({
  errorMessage,
  isLoading,
  onSubmit
}) => {
  const currentCountryCode = getCountryCode();
  const currentCallingCode = getCallingCode(currentCountryCode);

  return (
    <>
      <p className={styles.submitError}>{errorMessage}</p>

      <Formik
        initialValues={{
          phoneOrEmail: '',
          password: '',
          countryCode: currentCountryCode,
          callingCode: currentCallingCode
        }}
        onSubmit={onSubmit}
        validationSchema={LoginSchema}
      >
        {({
          errors,
          touched,
          dirty,
          values,
          setFieldValue,
          handleChange,
          handleBlur
        }) => (
          <Form>
            <div className={styles.group}>
              <p className={classnames(styles.label, styles.small)}>
                Phone or Email
              </p>
              <div className="relative">
                <PhoneEmailInput
                  testId="input-phone-or-email"
                  value={values.phoneOrEmail}
                  hasError={errors.phoneOrEmail && touched.phoneOrEmail}
                  countryCode={values.countryCode}
                  onBlur={handleBlur('phoneOrEmail')}
                  onChange={handleChange('phoneOrEmail')}
                  onCountryChange={(selected) => {
                    setFieldValue('countryCode', selected.countryCode);
                    setFieldValue('callingCode', selected.value);
                  }}
                />

                {errors.phoneOrEmail && touched.phoneOrEmail && (
                  <div className={classnames(styles.error, styles.right)}>
                    {errors.phoneOrEmail}
                  </div>
                )}
              </div>
            </div>

            <div className={styles.group}>
              <Button
                isSubmit
                disabled={!dirty}
                loading={isLoading}
                testId="button-submit"
                type={ButtonType.Primary}
              >
                Get Started
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default LoginForm;
