import styles from './FormWrapper.module.scss';
import React from 'react';
import { useRouter } from 'next/router';
import BackButton from 'legacy/common/components/back-button/BackButton';
import classNames from 'classnames';

type FormWrapperProps = {
  title?: string;
  subtitle?: string;
  children: React.ReactNode;
  showBack?: boolean;
  onBackClick?: () => void;
  isModal?: boolean;
};

const FormWrapper: React.FC<FormWrapperProps> = ({
  title,
  subtitle,
  children,
  showBack,
  onBackClick,
  isModal = false
}) => {
  const { back } = useRouter();

  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.modalWrapper]: isModal
      })}
    >
      <div className={styles.logo}>
        <img src="/img/Logo_Color.svg" />
      </div>

      <div className="mt-8 relative flex justify-center align-center">
        {showBack && (
          <BackButton onClick={onBackClick || (() => back())} left={0} />
        )}
        {title && <h1 className={styles.title}>{title}</h1>}
      </div>

      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}

      <div className={styles.content}>{children}</div>
    </div>
  );
};

FormWrapper.defaultProps = {
  title: 'Welcome to Bounce'
};

export default FormWrapper;
