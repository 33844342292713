import React from 'react';
import { useRouter } from 'next/router';
import { logEvent } from 'legacy/common/utils/amplitude';
import { AnalyticsEvent } from 'legacy/common/constants/events';

const OtherLoginMethods: React.FC = () => {
  const { query, push } = useRouter();

  const navigateToLoginSocial = () => {
    logEvent(AnalyticsEvent.CLICK_OTHER_LOGIN_METHODS);
    push({
      pathname: '/login/social',
      query
    });
  };

  return (
    <a className="text-primary-500 block mb-4" onClick={navigateToLoginSocial}>
      Login using other methods
    </a>
  );
};

export default OtherLoginMethods;
