import styles from 'legacy/common/components/form/Form.module.scss';

import React, { useState } from 'react';
import classnames from 'classnames';
import ReactCodeInput from 'react-verification-code-input';

import { useResendLoginVerificationCodeCommand } from 'legacy/auth/hooks';

import { VerificationSessionDto } from 'legacy/auth/types';

import FormWrapper from '../FormWrapper';
import Button, { ButtonType } from 'legacy/common/components/button/Button';
import SendConfirmationCodeToEmailModal from './SendConfirmationCodeToEmailModal';
import * as toast from 'legacy/common/utils/toast';

type LoginConfirmationProps = {
  isEmailLogin: boolean;
  verificationSession: VerificationSessionDto;
  onBackClick: () => void;
  onSubmit: (code: string) => void;
  isLoading: boolean;
  errorMessage?: string;
};

export const LoginConfirmationModalContent: React.FC<
  LoginConfirmationProps
> = ({
  isEmailLogin,
  verificationSession,
  onBackClick,
  onSubmit,
  isLoading,
  errorMessage
}) => (
  <LoginConfirmation
    isEmailLogin={isEmailLogin}
    verificationSession={verificationSession}
    onBackClick={onBackClick}
    onSubmit={onSubmit}
    isLoading={isLoading}
    errorMessage={errorMessage}
    isModal
  />
);

const LoginConfirmation: React.FC<
  LoginConfirmationProps & { isModal?: boolean }
> = ({
  isEmailLogin,
  verificationSession,
  onBackClick,
  onSubmit,
  isLoading,
  errorMessage,
  isModal = false
}) => {
  const [code, setCode] = useState('');
  const [
    showSendConfirmationCodeToEmailModal,
    setShowSendConfirmationCodeToEmailModal
  ] = React.useState(false);
  const [showSendAgainButton, setShowSendAgainButton] = React.useState(true);

  const resendLoginVerificationCode = useResendLoginVerificationCodeCommand();

  const handleOnClickSendAgain = () => {
    resendLoginVerificationCode.mutate(
      {
        verificationSessionId: verificationSession.verificationSessionId,
        deliveryMethod: 'sms'
      },
      {
        onSuccess: () => {
          toast.success(
            `Confirmation code sent to ${verificationSession.obfuscatedPhone}`
          );
        }
      }
    );
  };

  return (
    <FormWrapper
      title="Confirmation"
      showBack
      onBackClick={onBackClick}
      subtitle={
        isEmailLogin
          ? `We've sent a verification code to ${verificationSession.obfuscatedPhone}`
          : "We've sent a verification code to your phone number"
      }
      isModal={isModal}
    >
      <div className={styles.group}>
        <p className={styles.submitError}>
          {errorMessage || resendLoginVerificationCode.error?.message}
        </p>
      </div>
      <div className={styles.group} data-testid="form-confirmation">
        <p className={classnames(styles.label, styles.small)}>
          Verification Code
        </p>
        <div className={styles.codeInputContainer}>
          <ReactCodeInput
            fields={4}
            fieldWidth={45}
            fieldHeight={60}
            onComplete={(val) => {
              onSubmit(val);
              setCode(val);
            }}
            className={styles.codeInput}
            placeholder={['#', '#', '#', '#']}
          />
        </div>
      </div>
      <div className={styles.FormGroup}>
        <Button
          disabled={!code}
          loading={isLoading}
          type={ButtonType.Primary}
          onClick={() => {
            onSubmit(code);
          }}
        >
          Confirm Code
        </Button>

        {showSendAgainButton && (
          <div className="mt-4">
            <span className="mr-1 inline-block">Didn&apos;t receive?</span>
            {resendLoginVerificationCode.isLoading ? (
              <span>Sending Again...</span>
            ) : (
              <Button onClick={handleOnClickSendAgain} type={ButtonType.Text}>
                Send Again
              </Button>
            )}
          </div>
        )}

        <div className="mt-4">
          <Button
            onClick={() => setShowSendConfirmationCodeToEmailModal(true)}
            type={ButtonType.Text}
          >
            Can&apos;t access your phone?
          </Button>
        </div>
      </div>

      {showSendConfirmationCodeToEmailModal && (
        <SendConfirmationCodeToEmailModal
          onSendConfirmationCodeSuccess={() => setShowSendAgainButton(false)}
          onClose={() => setShowSendConfirmationCodeToEmailModal(false)}
          verificationSession={verificationSession}
        />
      )}
    </FormWrapper>
  );
};

export default LoginConfirmation;
