import animation from './animation.module.scss';
import styles from 'legacy/common/components/form/Form.module.scss';

import React, { useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';

import { FaTimes } from 'react-icons/fa';

import classnames from 'classnames';
import { Field, Form, Formik } from 'formik';

import Button, { ButtonType } from 'legacy/common/components/button/Button';
import Link from 'next/link';

const LoginWithPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Required')
    .min(8, 'Must be at least 8 characters')
});

type LoginFormProps = {
  isLoading: boolean;
  errorMessage: string;
  phoneOrEmail: string;
  onReset: () => void;
  onSubmit: (values: { password: string }) => void;
};

const LoginForm: React.FC<LoginFormProps> = ({
  errorMessage,
  isLoading,
  phoneOrEmail,
  onReset,
  onSubmit
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePasswordVisibility = () => {
    setIsPasswordVisible((isPasswordVisible) => !isPasswordVisible);
  };

  const passwordRef = useRef<HTMLInputElement>();
  useEffect(() => {
    passwordRef.current?.focus();
  }, []);

  return (
    <>
      <p className={styles.submitError}>{errorMessage}</p>

      <Formik
        initialValues={{
          phoneOrEmail,
          password: ''
        }}
        onSubmit={onSubmit}
        validationSchema={LoginWithPasswordSchema}
      >
        {({ errors, touched, dirty }) => (
          <Form>
            <div className={styles.group}>
              <p className={classnames(styles.label, styles.small)}>
                Phone or Email
              </p>
              <div className="relative">
                <Field
                  type="text"
                  id="phoneOrEmail"
                  name="phoneOrEmail"
                  disabled
                  data-testid="input-phone-or-email"
                  placeholder="Enter your phone or email"
                  className={styles.input}
                />

                <div
                  data-testid="reset-verification-flow"
                  className="absolute right-4 top-4 cursor-pointer"
                  // `onBlur` handler of the password field runs before the `onClick` handler.
                  // When the password is empty, there is a condition where the "Required" error shows up and causes the button to shift a little,
                  // preventing the click on the cross button.
                  // onMouseDown should trigger as soon as you press down on the button, rather than press up
                  onMouseDown={onReset}
                >
                  <FaTimes className="text-lg text-gray-600" />
                </div>
              </div>

              <div className={classnames('mt-4', animation.fadeIn)}>
                <p className={classnames(styles.label, styles.small)}>
                  Password
                </p>
                <div className={styles.relative}>
                  <Field
                    id="password"
                    name="password"
                    innerRef={passwordRef}
                    type={isPasswordVisible ? 'text' : 'password'}
                    placeholder="Enter your password"
                    data-testid="input-password"
                    className={classnames(styles.input, {
                      [styles.errorField]:
                        dirty && errors.password && touched.password
                    })}
                  />
                  {dirty && errors.password && touched.password && (
                    <div className={classnames(styles.error, styles.right)}>
                      {errors.password}
                    </div>
                  )}
                  <div
                    className={styles.inputIcon}
                    onClick={togglePasswordVisibility}
                  >
                    {isPasswordVisible ? (
                      <img src="/img/icons/eye.svg" />
                    ) : (
                      <img src="/img/icons/eye-slash.svg" />
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className={classnames(styles.group, animation.slideIn)}>
              <Button
                isSubmit
                disabled={!dirty}
                loading={isLoading}
                testId="button-submit"
                type={ButtonType.Primary}
              >
                Get Started
              </Button>
            </div>
            <div className={classnames('mb-10', animation.fadeIn)}>
              Forgot Password?{' '}
              <Link href="/login/resetPassword" className="text-primary-500">
                Reset
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default LoginForm;
