import styles from 'legacy/common/components/form/Form.module.scss';

import React from 'react';
import classnames from 'classnames';

import PhoneInput from 'legacy/common/components/phone-input/PhoneInput';

const isNumber = (str: string) => /^[0-9]+$/.test(str);

type PhoneEmailInputProps = {
  value: string;
  hasError: boolean;
  countryCode: string;
  testId?: string;
  phoneInputTestId?: string;
  emailInputTestId?: string;
  onChange: (e: any) => void;
  onBlur: (e: any) => void;
  onCountryChange: (selectedCountry: {
    countryCode: string;
    value: string;
  }) => void;
};

const PhoneEmailInput: React.FC<PhoneEmailInputProps> = ({
  value,
  testId,
  phoneInputTestId,
  emailInputTestId,
  hasError,
  countryCode,
  onCountryChange,
  onChange,
  onBlur
}) => {
  if (value.length > 2 && isNumber(value)) {
    return (
      <PhoneInput
        value={value}
        hasError={hasError}
        testId={testId || phoneInputTestId}
        countryCode={countryCode}
        onCountryChange={onCountryChange}
        onChange={onChange}
        onBlur={onBlur}
        autoFocus
      />
    );
  }

  return (
    <input
      type="text"
      value={value}
      data-testid={testId || emailInputTestId}
      placeholder="Enter your phone or email"
      onChange={onChange}
      onBlur={onBlur}
      className={classnames(styles.input, {
        [styles.errorField]: hasError
      })}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
    />
  );
};

export default PhoneEmailInput;
